import React, { useState } from "react"
import { Link } from "gatsby"
import { emailadres } from "../utility/strings"

const Menu = () => {
  const [menuOpenState, setMenuOpenState] = useState(false)
  return (
    <>
      <div className="menu-wrapper">
        <div className={menuOpenState ? "menu" : "menu menu--closed"}>
          <nav>
            <button
              className="button button--white"
              tabIndex="0"
              aria-expanded={menuOpenState ? "true" : "false"}
              aria-controls="menu-list"
              onClick={() => setMenuOpenState(!menuOpenState)}
            >
              {!menuOpenState ? "Menu" : "Sluit menu"}
            </button>
            <ul id="menu-list">
              <li>
                <Link
                  className="text-button text-button--transparent"
                  to="/"
                  activeClassName="current"
                >
                  Terug naar begin
                </Link>
              </li>
              <li>
                <Link
                  className="text-button text-button--transparent"
                  to="/nieuws2025/"
                  activeClassName="current"
                >
                  Naar de nieuwsartikelen
                </Link>
              </li>
              <li>
                <Link
                  className="text-button text-button--transparent"
                  to="/missie/"
                  activeClassName="current"
                >
                  Naar de missie
                </Link>
              </li>
              <li>
                <Link
                  className="text-button text-button--transparent"
                  to="/verhalen/"
                  activeClassName="current"
                >
                  Naar de verhalen
                </Link>
              </li>
              <li>
                <Link
                  className="text-button text-button--transparent"
                  to="/gerelateerd/"
                  activeClassName="current"
                >
                  Gerelateerd
                </Link>
              </li>
              <li>
                <Link
                  className="text-button text-button--transparent"
                  to="/privacy/"
                  activeClassName="current"
                >
                  Privacy
                </Link>
              </li>
            </ul>
          </nav>
          <div>
            <a
              className="button button--small has-margin-top-tiny"
              href={`mailto:${emailadres}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Reageer
            </a>
            <br></br>
            <a
              className="button button--small button--white has-margin-top-tiny"
              href={
                "https://www.biebtobieb.nl/do/startpage?id=862846-737461727470616765"
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="small-text">Praat mee op</span>
              <br></br>
              bieb<span className="orange-text">to</span>bieb.nl
            </a>
          </div>
          <div
            className="menu-backdrop"
            onClick={() => setMenuOpenState(!menuOpenState)}
          ></div>
        </div>
        <div className="menu__logos">
          <img src="/images/bisc.png" alt="bisc logo"></img>
          <img src="/images/cubiss.png" alt="cubiss logo"></img>
        </div>
      </div>
    </>
  )
}

export default Menu
