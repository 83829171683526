import React from "react"
import { Helmet } from "react-helmet"
import Menu from "./menu"

const Layout = ({ children }) => (
  <main>
    <Helmet title="Bibliotheek 2025" defer={false} />
    <Menu />
    {children}
  </main>
)

export default Layout
